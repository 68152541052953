@import "~bootstrap/scss/bootstrap";

@font-face {
  font-family: "Cera Pro";
  src: url("../fonts/cera_pro_bold.otf") format('opentype');
}

@font-face {
  font-family: "Cera Pro";
  font-weight: bold;
  src: url("../fonts/cera_pro_black.otf") format('opentype');
}

html, body {

  /** SPECIALIST SIZE **/
  @media (max-width: 1367px) {
    font-size: 11px;
  }

  @include media-breakpoint-down(xl) {
    font-size: 1rem;
  }
}

body {
  font-family: "Cera Pro", sans-serif;
  //font-size: calc(14px + (26 - 14) * ((100vw - 300px) / (1600 - 300)));
  height: 100vh;
  width: 100vw;
  display: block;
  background-color: #000;

  @include media-breakpoint-down(xl) {
    background-color: #afb5ba;
    width: unset;
    height: unset;
  }
}

.nav {
  position: relative;
  z-index: 100;

  @include media-breakpoint-down(xl) {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
  }

  img {
    width: 3.125rem;

    @include media-breakpoint-down(xl) {
      width: 2.1875rem;
    }
  }
}

.app {
  background-size: 100%;
  background-repeat: no-repeat;
  position: relative;
  width: 100vw;
  height: 100vh;

  @include media-breakpoint-down(xl) {
    width: unset;
    height: unset;
  }

  &--switch-lang {
    position: absolute;
    top: 1rem;
    right: 1rem;
    display: flex;
    height: 3.125rem;
    width: 3.125rem;
    border-radius: 100%;
    background-color: #2F45E1;
    border: 5px solid white;
    z-index: 1000;
    transition: 1s ease-in-out;
    color: white;
    vertical-align: middle;
    align-items: center;
    text-align: center;
    font-size:1.2rem;

    @include media-breakpoint-down(lg) {
      height: 2.1875rem;
      width: 2.1875rem;
      border-radius: 100%;
      border: 3px solid white;
      font-size:0.90rem;
    }

    &:hover {
      border: 5px solid white;
      background-color: #2F45E1;

      @include media-breakpoint-down(lg) {
        border: 3px solid white;
      }
    }
  }

  &--back-button {
    position: absolute;
    top: 1rem;
    left: 1rem;
    display: flex;
    height: 3.125rem;
    width: 3.125rem;
    border-radius: 100%;
    background-color: #2F45E1;
    border: 5px solid white;
    z-index: 1000;
    transition: 1s ease-in-out;
    color:white;

    .white{
      padding-left: 40px;
      font-size: 20px;
      line-height: 20px;
    }

    @include media-breakpoint-down(lg) {
      height: 2.1875rem;
      width: 2.1875rem;
      border-radius: 100%;
      border: 3px solid white;
    }

    &:hover {
      border: 5px solid white;
      background-color: #2F45E1;

      @include media-breakpoint-down(lg) {
        border: 3px solid white;
      }
    }

    svg {
      margin: auto;
      font-size: 2em !important;

      @include media-breakpoint-down(lg) {
        font-size: 1.5em !important;
      }

      path {
        fill: #FFFFFF;
      }
    }
  }

  &--question-block {

    @include media-breakpoint-down(xl) {
      padding: 0;
    }
  }

  &--animations {
    position: absolute;
    top: 0;

    &__3,
    &__10,
    &__11 {
      z-index: 1;
    }
  }

  &--container {
    padding: 1.5rem 6.25rem 6.25rem;
    height: calc(100% - 4.75rem);

    @include media-breakpoint-down(xl) {
      width: 100%;
      padding-right: var(--bs-gutter-x, 0.75rem);
      padding-left: var(--bs-gutter-x, 0.75rem);
      margin-right: auto;
      margin-left: auto;
      padding-top: unset;
      padding-bottom: unset;
      height: unset;
    }
  }

  &--row {
    height: 100%;

    @include media-breakpoint-down(xl) {
      height: unset;
    }
  }

  &--questions {
    background-color: white;
    border-radius: 2.5rem;
    padding: 2.5rem;
    position: relative;
    height: 100%;
    
    @include media-breakpoint-down(xl) {
      padding: 0;
      border-radius: 0;
      height: unset;
    }

    &--number {
      font-size: 1.8rem;
      color: #19d3af;
      font-weight: bold;

      @media (max-width: 320.98px) {
        font-size: 1.2rem;
      }

      @include media-breakpoint-down(xl) {
        //font-size: 1.5rem;
        background-color: #FFFFFF;
        padding: {
          top: 1.25rem;
          left: 1.25rem;
          right: 1.25rem;
        }
      }

      @include media-breakpoint-between(xl, xxl) {
        font-size: 1.6rem;
      }

      @media (min-width: 1400px) and (max-width: 1599.98px) {
        font-size: 1.2rem;
      }
    }

    &--question {
      font-size: 2.8rem;
      color: #19d3af;
      font-weight: bold;
      line-height: 1.2em;
      margin-top: 0.625rem;

      @media (max-width: 320.98px) {
        font-size: 2rem;
      }

      @media (min-width: 321px) and (max-width: 399.98px) {
        font-size: 2.5rem;
      }

      @include media-breakpoint-down(xl) {
        //font-size: 2.25rem;
        background-color: #FFFFFF;
        padding: {
          left: 1.25rem;
          right: 1.25rem;
        }
      }

      @media (min-width: 1400px) and (max-width: 1599.98px) {
        font-size: 1.8rem;
      }

      br {
        display: none;
      }

      span {
        display: inline-block;
        background-color: #19d3af;
        color: white;
        padding: 0.3125rem;
      }
    }
  }

  &--options {
    position: absolute;
    padding: 1.25rem;
    border-radius: 2.5rem;
    bottom: 1.25rem;
    left: 1.25rem;
    right: 1.25rem;
    background-color: #afb5ba;
    min-height: 50%;
    max-height: 65%;
    display: block;
    z-index: 10;

    @media (min-width: 1400px) and (max-width: 1599.98px) {
      font-size: 1.2rem;
      max-height: 60%;
    }

    @include media-breakpoint-down(xl) {
      position: relative;
      min-height: unset;
      max-height: unset;
      bottom: unset;
      left: unset;
      right: unset;
      margin-top: 1.25rem;
      border-radius: 0;
    }

    &--responded {

      @include media-breakpoint-between(md, lg) {
        height: 100%;
        display: block;
      }
    }

    &--choose {
      display: flex;
      background-color: #efefef;
      color: #5f6b70;
      min-height: 4.1875rem;
      text-align: center;
      margin-bottom: 1.25rem;
      border-radius: 3.125rem;
      position: relative;
      border: 1px solid #efefef;

      @media (min-width: 1400px) and (max-width: 1599.98px) {
        min-height: 2.75rem;
        height: calc(20% - 0.625rem);
        margin-bottom: 0.625rem;
      }

      @include media-breakpoint-between(md, lg) {
        min-height:4.375rem;
      }

      @media (max-width: 320.98px) {
        min-height: 3.4375rem;
        margin-bottom: 0.625rem;
      }

      @include media-breakpoint-down(xl) {
        //min-height: 3.4375rem;
        //margin-bottom: 0.625rem;
      }

      &:last-child {
        margin-bottom: 0;
      }

      &:hover {
        background-color: #19d3af;
        border: 1px solid #fff;
        color: white;
        cursor: pointer;

        .number {
          background-color: white;
          color: #19d3af;
        }
      }

      .number {
        position: relative;
        width: 2.8125rem;
        height: 2.8125rem;
        border-radius: 50%;
        display: flex;
        top: 0.625rem;
        left: 0.625rem;
        background-color: #19d3af;
        color: white;

        @media (min-width: 1400px) and (max-width: 1599.98px) {
          width: 1.75rem;
          height: 1.75rem;
          top: 0.5rem;
          left: 0.5rem;
        }

        @media (max-width: 320.98px) {
          width: 2.1875rem;
          height: 2.1875rem;
        }

        @include media-breakpoint-down(xl) {
          //width: 2.1875rem;
          //height: 2.1875rem;
        }

        span {
          margin: auto;
          font-size: 1.7rem;
          font-weight: bold;

          @media (min-width: 1400px) and (max-width: 1599.98px) {
            font-size: 1rem;
          }

          @media (max-width: 320.98px) {
            font-size: 1.2rem;
          }

          @media (min-width: 321px) and (max-width: 399.98px) {
            font-size: 1.4rem;
          }

          @include media-breakpoint-down(xl) {
            //font-size: 1.2rem;
          }

          @include media-breakpoint-between(xl, xxl) {
            font-size: 1.375rem;
          }
        }
      }

      .label {
        margin: auto;
        font-size: 1.7rem;
        font-weight: bold;

        @media (min-width: 1400px) and (max-width: 1599.98px) {
          font-size: 1.2rem;
        }

        @media (max-width: 320.98px) {
          font-size: 1.2rem;
        }

        @media (min-width: 321px) and (max-width: 399.98px) {
          font-size: 1.4rem;
        }

        @include media-breakpoint-down(xl) {
          //font-size: 1.2rem;
        }

        @include media-breakpoint-between(xl, xxl) {
          font-size: 1.375rem;
        }
      }

      &__disabled {
        background-color: #bec5c7;
        border-color: #bec5c7;

        &:hover {
          cursor: default;
          background-color: #bec5c7;
        }
      }
    }
  }

  &--response {
    position: absolute;
    display: flex;
    background-color: #FFF;
    padding: 1.875rem;
    border-radius: 2.5rem;
    height: calc(100% - 2.5rem);
    width: calc(100% - 2.5rem);

    @media (min-width: 1400px) and (max-width: 1599.98px) {
      padding: 1.25rem;
      width: calc(100% - 2.5rem);
    }

    @include media-breakpoint-down(xl) {
      position: relative;
      height: 100%;
      width: 100%;
      min-height: 40vh;
    }

    &__graphic {
      display: block;
      position: absolute;
      top: 1.875rem;
      left: 1.875rem;
      bottom: 60%;
      right: 1.875rem;

      @include media-breakpoint-down(xl) {
        display: none;
      }

      @media (min-width: 1400px) and (max-width: 1599.98px) {
        display: none;
      }
    }

    &__feedback {
      display: block;
      position: absolute;
      top: 10%;
      left: 1.875rem;
      bottom: 1.875rem;
      right: 1.875rem;
      color: #5f6b70;
      //min-height: calc(40vh + 40px);

      @media (min-width: 1400px) and (max-width: 1599.98px) {
        font-size: 1rem;
        display: block;
        position: relative;
        top: unset;
        left: unset;
        bottom: unset;
        right: unset;
        width: 100%;
        overflow-y: auto;
        height: 100%;
      }

      @include media-breakpoint-down(xl) {
        display: block;
        position: relative;
        top: unset;
        left: unset;
        bottom: unset;
        right: unset;
        width: 100%;
        overflow-y: auto;
      }

      p:first-of-type{
        height: 55%;
        display: block;
        overflow-x: hidden;
        overflow-y: scroll;
      }

      h4 {
        font-size: 1.75rem;
        font-weight: bold;

        @media (min-width: 1400px) and (max-width: 1599.98px) {
          font-size: 1.25rem;
        }
      }
    }

    &__pdf {

      a {
        display: inline-block;
        position: absolute;
        bottom: 0;
        padding: 0.9375rem 1.5625rem;
        text-decoration: none;
        font-weight: bold;
        border-radius: 2.5rem;
        border: 1px solid white;
        color: white;
        background-color: #19d3af;
        left: 0;

        @media (min-width: 1400px) and (max-width: 1599.98px) {
          position: unset;
          padding: 0.5rem 0.9375rem;
        }

        @include media-breakpoint-down(xl) {
          padding: 0.5rem 0.9375rem;
        }

        &:hover {
          color: white;
          text-decoration: none;
          cursor: pointer;
        }
      }
    }

    &--btn {
      display: inline-block;
      position: absolute;
      bottom: 0;
      padding: 0.9375rem 1.5625rem;
      text-decoration: none;
      font-weight: bold;
      border-radius: 2.5rem;
      border: 1px solid white;
      color: white;

      @media (min-width: 1400px) and (max-width: 1599.98px) {
        position: unset;
        padding: 0.5rem 0.9375rem;
      }

      @media (max-width: 399.98px) {
        padding: 0.5rem 0.9375rem;
      }

      &:hover {
        color: white;
        text-decoration: none;
        cursor: pointer;
      }

      &__recommendations {
        background-color: #19d3af;
        left: 0;
      }

      &__next {
        background-color: #5f6b70;
        right: 0;
      }
    }
  }
}

.video {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;

  @include media-breakpoint-down(xl) {
    position: relative;
    width: 100%;
  }
}